.sub-menu-items{
    list-style: none;
    padding: 0.4rem;
    color: white;
}
.sub-menu-items > .active{
    color: rgb(16, 136, 136);
}
.sub-menu-items > a{
    color: white;
    text-decoration: none;
}

@media (max-width: 998px){
    .sub-menu-items{
        padding-left: 29px;
        width: 100vw;
    }
}