.menu-items{
    list-style: none;
}
.menu-items > .active{
    color: rgb(16, 136, 136);
}
.menu-items > a {
    text-decoration: none;
    background: transparent;
    color: white;
    font-family: var(-font-family);
    cursor: pointer;
}
.menu-button{
    background: transparent;
    border: none;
    color: white;
    font-size: 1rem;
    outline: none;
    cursor: pointer;
    font-family: var(-font-family);
}
.menu-bar{
    position: relative;
    height: 2px;
    width: 100%;
    background: white;
}

@media(max-width: 998px){
    .menu-items{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}