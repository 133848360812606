.objectives-body{
    margin: 5rem 8rem;
    display: flex;
    flex-direction: column;
    text-align: start;
    justify-content: flex-start;
    gap: 1rem;
}
.objectives-content{
    margin-left: 40px;
}
.objectives-content > ul {
    list-style: square;
}

.objectives-content > ul > li {
    font-size: 0.9rem;
    text-align: justify;
    color: var(--second-font-color);
    margin-bottom: 15px;
}

@media(max-width:998px){
    .objectives-body{
        margin: 3rem 2rem;
    }
}