*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body{
  background-color: var(--color-bg);
  font-family: var(--main-font)
}

.main_loader{
  position: absolute;
  top: 50%;
  left: 44%;
  margin-right: -50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  gap: 3;
}
.main_loader > ClockLoader{
  color: red;
}

.title_stroke{
  height: 0.3rem;
  width: 5rem;
  background: var(--third-color);
}

.heading{
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: bold;
    position: relative;
    top: 3px;
}
.sub_heading{
  font-size: 1rem;
  color: var(--third-color);
  font-weight: bold;
}
.read_more{
  font-size: o.9rem;
  color: var(--forth-color);
  cursor: pointer;
  text-decoration: none !important;
}

.dropdown {
  position: absolute;
  right: auto;
  left: auto;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  width: 400px;
  display: none;
  background: var(--fifth-color);
  padding-top: 15px;
}
.dropdown > hr{
  color: rgba(102, 51, 153, 0.555);
}
.dropdown > hr:last-child{
  display: none;
}

.dropdown.show {
  display: block;
}

.dropdown_mobile {
  display: none;
  background: transparent;
}
.dropdown_mobile > hr{
  color: rgba(102, 51, 153, 0.555);
}
.dropdown_mobile > hr:last-child{
  display: none;
}

.dropdown_mobile.show {
  display: block;
} 

.details-page-sub_heading > h4{
  background-color: var(--third-color);
  margin-bottom: 30px;
  width: fit-content;
  padding: 5px 20px;
}

table {
  border-collapse: collapse;
  width: 100%;
}
th{
  font-size: 15px;
}
td{
  font-size: 13px;
}

th, td {
  text-align: left;
  padding: 8px;
  border: 1px solid #cac3c3b5;
  color: var(--second-font-color);
}
td > img {
  object-fit: cover;
  object-position: center;
}
hr{
  color: #cac3c3b5;
  height: 1px;
  margin-bottom: 10px;
}

@media(max-width:998px){
  .title_stroke{
    height: 0.2rem;
  }
  
  .heading{
      font-size: 1.2rem;
  }
  .sub_heading{
    font-size: 1rem;
  }
  .read_more{
    font-size: o.7rem;
  }
}
