.qac-body {
  height: 650px;
  margin: 5rem 0rem 0rem 0rem;
  background-color: #ffff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

.qac-content {
  margin: 0rem 4rem;
  padding: 5rem 0rem;
}
.qac-team {
  margin: 5rem 0rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.profile-content {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: flex-start;
  gap: 1rem;
  padding: 3rem 1rem;
  border: 1px solid var(--second-font-color);
}

.qac-profile-image > img {
  border: 2px solid var(--second-font-color);
  width: 150px;
  height: 150px;
  object-fit: fill;
  border-radius: 5px;
}
.details > h4 {
  font-size: 0.9rem;
  font-weight: 800;
  color: var(--second-font-color);
  margin: 10px 0;
}
.details > p {
  font-size: 0.8rem;
  color: var(--second-font-color);
}

@media (max-width: 998px) {
  .qac-body {
    height: 100%;
  }
  .qac-team {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .qac-content {
    margin: 0rem 2rem;
    padding: 2rem 0rem;
  }
  .profile-content {
    justify-content: center;
    align-items: center;
  }
}
