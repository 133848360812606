.menu-items > a{
    padding: 10px;
}
@media(max-width: 998px){
    .menu-items{
        background-color: var(--main-color);
        cursor: pointer;
        border-bottom: 1px solid rebeccapurple;
        font-family: var(-font-family);
    }
    .menu-button{
        cursor: pointer;
        padding: 0.5rem;
        font-family: var(-font-family);
        
    }
}