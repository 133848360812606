.image-container {
  max-width: 1200px;
  margin: 2rem auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  gap: 0.3rem;
}
.image {
  width: 100%;
  height: 13rem;
  cursor: pointer;
}

.image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}
