.slider-container{
    height: 470px;
    width: 100%;
    position: relative;
    z-index: -0;
}
.slider-image{
    position: relative;
    height: 470px;
    width: 100%;
    object-position: center;
    object-fit: contain;
}
.slider-image img{
    height: 100%;
    width: 100%;
    position: absolute;
}
.arrows{
    font-size: 2.5rem;
    color: var(--third-color);
    display: flex;
    justify-content: space-between;
    padding: 0 4rem;
    position: relative;
    bottom: 50%;
    left: 0;
    cursor: pointer;
}
.dot{
    display: flex;
    gap: 1rem;
    justify-content: center;
}

.carousel-indicator-item {
    height: 15px;
    width: 15px;
    background: var(--third-color);
    position: relative;
    /* left: 50%; */
    margin-top: -100px;
    border-radius: 50%;
    display: flex;
    outline: none;
    border: none;
    opacity: 0.3;
    cursor: pointer;
}
.active{
    opacity: 1;
}

@media(max-width: 998px){
    .slider-container{
        height: 250px;
    }
    .slider-image{
        height: 250px;
    }
    .carousel-indicator-item {
        height: 11px;
        width: 11px;
    }
    .arrows{
        font-size: 2rem;
    }
}

@media(min-width: 1400px){
    .slider-container{
        height: 680px;
    }
    .slider-image{
        height: 680px;
    }
    .carousel-indicator-item {
        height: 11px;
        width: 11px;
    }
    .arrows{
        font-size: 2rem;
    }
}