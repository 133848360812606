.welcome_message_body {
  margin: 5rem 8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.welcome_message_body > div {
  width: 90%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 2px 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.welcome_message_body > div > h3 {
  font-weight: 400;
  color: #665656;
  text-transform: uppercase;
  margin-top: 40px;
  margin-bottom: 10px;
  font-weight: 600;
}

.welcome_message_body > div > p {
  background-color: #ffffff;
  padding: 1.5rem;
  text-align: justify;
  font-weight: 400;
  color: #665656;
  line-height: 28px;
}

@media (max-width: 998px) {
  .welcome_message_body {
    margin: 2rem 1rem;
  }
}
