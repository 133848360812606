.event-container {
  display: flex;
  flex-direction: column;
  background: var(--main-color);
  height: 580px;
}

.event-body {
  padding: 4rem 8rem;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
}

.event-h > span {
  color: white;
}

.event-content {
  display: flex;
  max-width: 100%;
  max-height: 385px;
  padding: 2rem 3rem;
  margin-top: 1rem;
  position: relative;
  align-items: center;
  justify-content: center;
}
.event-content > div > img {
  width: 485px;
  height: 357px;
  object-fit: cover;
}
.event-short-details {
  width: 611px;
  height: 308px;
  background: var(--sixth-color);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  position: relative;
  left: -5%;
}
.event-short-details > div > p {
  padding: 2rem;
  color: white;
  font-size: 1.2rem;
  text-align: justify;
}
.event-short-details > div > span {
  position: absolute;
  bottom: 10%;
  right: 10%;
}

.arrow-e {
  padding: 0 6rem;
  display: flex;
  justify-content: space-between;
  font-size: 2rem;
  color: white;
  position: relative;
  top: -10%;
  cursor: pointer;
}

@media (max-width: 998px) {
  .event-container {
    display: flex;
    flex-direction: column;
    background: var(--fifth-color);
    height: auto;
  }

  .event-body {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
  }

  .event-h > span {
    color: white;
  }

  .event-content {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    max-height: 385px;
    padding: 0;
    margin-top: 1rem;
    position: relative;
    align-items: center;
    justify-content: flex-start;
  }
  .event-content > div > img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
  .event-short-details {
    width: 100%;
    height: 100%;
    background: var(--sixth-color);
    padding: 1rem;
    padding-bottom: 2rem;
    display: flex;
    flex-direction: column;
    position: relative;
    left: 0;
  }
  .event-short-details > div > p {
    padding: 0rem;
    color: white;
    font-size: 1.2rem;
    text-align: justify;
  }
  .event-short-details > div > span {
    position: absolute;
    bottom: 3%;
    right: 0%;
  }

  .arrow-e {
    padding: 3rem 2rem 1rem;
    font-size: 1.4rem;
  }
}
