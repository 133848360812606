.news-events-body{
    height: 450px;
    padding: 4rem 8rem;
}
.news-events-content{
  background-color: red;
}

.gallery {
      border: 1px solid #ccc;
      transition: box-shadow .3s;
    }
  
.gallery:hover {
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;  
  }
  
.gallery img {
    width: 100%;
    height: auto;
  }
  
.desc {
    padding: 15px;
    text-align: start;
    cursor: pointer;
  }
  
  * {
    box-sizing: border-box;
  }
  
  .responsive {
    padding: 0 6px;
    float: left;
    width: 24.99999%;
  }

  .event-details{
    padding: 4rem 8rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 2rem;
  }

  .event-heading> h2 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 3rem;
  }

  .event-thumbnail{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .event-thumbnail > img{
    width: 80%;
    height: 80%;
    object-fit: contain;
  }
  .event-content-details{
    padding-top: 30px;
    text-align: justify;
  }
  .event-content-details > p{
    font-size: 1rem;
    color: var(--second-font-color);
    line-height: 28px;
  }
  
  @media only screen and (max-width: 700px) {
    .responsive {
      width: 49.99999%;
      margin: 6px 0;
    }
  }
  
  @media only screen and (max-width: 500px) {
    .responsive {
      width: 100%;
    }
  }
  
  .clearfix:after {
    content: "";
    display: table;
    clear: both;
  }