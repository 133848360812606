.gallery-body {
  max-width: 1200px;
  margin: 2rem auto;
  height: 100% !important;
  padding-bottom: 5rem;
}

.gallery-content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  gap: 1rem;
}
.item_heading {
  font-size: 0.9rem;
  font-weight: 400;
}
.gallery_item {
  margin-top: 55px;
  width: 100%;
  height: 13rem;
}
.gallery_item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-position: center;
}
