.menu-header{
    position: sticky;
    display: flex;
    justify-content: flex-end;
    gap: 3;
    align-items: center;
    background: var(--main-color);
    color: white;
    font-size: 1rem;
    padding-right: 4rem;
    z-index: 9999;
}

.menu-list{
    position: sticky !important;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
}
.menu-item{
    position: relative;
    list-style: none;
    cursor: pointer;
}
.menu-items > a:hover{
    color: rgb(16, 136, 136);
}
.sub-menu-items > a:hover{
    color: rgb(16, 136, 136);
}
.sub-menu-items > a {
    font-size: 0.8rem;
}
.mobile-view{
    display: none;
}
.menu-header-mobile-view{
    display: none;
}
.iqac__navbar-mobile-menu{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem 0 0;
    position: relative;
}
.iqac__navbar-mobile-menu-item{
    display: none;
}
.iqac__navbar-mobile-menu_container{
    background: var(--main-color);
    position: absolute;
    top: 100%;
    z-index: 999;
    width: 300px;
    left: 0%;
    right: 5%;
    width: 100%;
    font-size: 1.3rem;
}

@media(max-width: 998px){
    .menu-header{
        display: none;
    }

    .header{
        background: white;
    }
    .mobile-view{
        display: block;
        display: flex;
        justify-content: space-between;
        padding: 1rem;
        align-items: center;
    }
    .mobile-view-left-th{
        display: flex;
        justify-content: space-between;
        align-items: center;

    }
    .mobile-view-left-th > :nth-child(1) {
        height: 45px;
        width: 118px;
    }
    .mobile-view-left-th > :nth-child(2) {
        height: 45px;
        width: 118px;
    }
    .iqac__navbar-mobile-menu-item{
        display: block;
    }
}
