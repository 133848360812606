.header-top{
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 0 8rem;
}
.left-th{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0px;
}
.left-th > img{
    width: 160px;
    height: 75px;
}

.right-th{
    display: flex;
    justify-content: center;
    align-items: center;
}

.right-th > img{
    width: 218;
    height: 59px;
}

@media (max-width: 998px ){
    .header-top{
        display: none;
    }
}

@media(max-width: 1080px){
    .header-top{
        padding: 0 2rem;
    }
    .left-th > img{
        width: 120px;
        height: 80px;
    }
    .right-th > img{
        width: 118;
        height: 39px;
    }
}
@media(max-width: 1180px){
    .header-top{
        padding: 0 2rem;
    }
}


