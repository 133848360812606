.mission-vision-body{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 350px;
    background-color: #9CC9FF;
}
.mission-vision-body > .mission {
    display: flex;
    justify-content: flex-start;
    gap: 0.9rem;
    flex-direction: column;
    padding: 4rem 3rem;
    width: 50%;
    height: 100%;
}

.mission-vision-body > div > p{
    color: black;
    text-align: justify;
    font-size: 1rem;
}

.mission-vision-body > div > h3{
    color: var(--third-color);
    text-align: justify;
    font-size: 1rem;
    text-transform: uppercase;
}

.mission-vision-body > .objective {
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
    flex-direction: column;
    padding: 4rem 3rem;
    width: 50%;
    height: 100%;
}
.mission-vision-body > .objective > ul > li{
    text-align: justify;
}

.mission-vision-body > .vision {
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
    flex-direction: column;
    padding: 4rem 3rem;
    width: 50%;
    height: 100%;
}

.mission-vision-details-body{
    margin: 5rem 8rem;
    display: flex;
    flex-direction: column;
    text-align: start;
    justify-content: flex-start;
    gap: 1rem;
}

.mission-vision-details-content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
    align-items: flex-start;
}
.mission-vision-d{
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    justify-content: flex-start;
    align-items: flex-start;
}
.mission-vision-d > h3{
    font-size: 1rem;
    color: var(--second-font-color);
    text-transform: uppercase;
    font-weight: 600;
}
.mission-vision-d > p{
    font-size: 0.9rem;
    text-align: justify;
    color: var(--second-font-color);
}

@media(max-width:998px){
    .mission-vision-body{
        flex-direction: column;
        height: 100%;
        align-items: flex-start;
        margin: 2rem 1rem;
    }
    .mission-vision-body > .mission{
        padding: 1rem;
        width: 100%;
    }
    .mission-vision-body > .vision{
        padding: 2rem 1rem;
        width: 100%;
    }

    .mission-vision-body > .objective{
        padding: 2rem 1rem;
        width: 100%;
    }
    .mission-vision-body > .objective > ul > li{
        margin-left: 30px;
    }

    .mission-vision-details-body{
        margin: 3rem 2rem;
    }
}