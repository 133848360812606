/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;0,900;1,400;1,500&display=swap');


:root {
  --font-family: 'Roboto', sans-serif;

  --gradient-text: linear-gradient(89.97deg, #AE67FA 1.84%, #F49867 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%);
  
  --color-bg: #ffff;
  --color-footer : #0B0646;
  --main-color : #0B0646;
  --second-color: rgba(12, 5, 101, 0.72);
  --third-color: #E77421;
  --forth-color: #B8EC8F;
  --second-font-color: #665656;
  --fifth-color: rgba(11, 6, 70, 0.85);
  --sixth-color: rgba(51, 68, 156, 0.72);
  --seventh-color: rgba(17, 11, 90, 1);
  --main-font: 'Roboto', sans-serif;
}
