.footer-container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  background: var(--main-color);
}
.footer-body {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 2rem 8rem;
}
.footer-content > :nth-child(1) {
  font-size: 0.9rem;
  color: white;
}

.item-f > ul > li {
  display: flex;
  justify-content: flex-start;
  gap: 0.5rem;
  align-items: center;
  list-style: none;
  color: white;
  margin-top: 10px;
  font-size: 0.8rem;
  cursor: pointer;
}

.item-f > ul > a > li {
  display: flex;
  justify-content: flex-start;
  gap: 0.5rem;
  align-items: center;
  list-style: none;
  color: white;
  margin-top: 10px;
  font-size: 0.8rem;
  cursor: pointer;
}

.social-icon {
  display: flex;
  margin-top: 10px;
  gap: 0.5rem;
  justify-content: flex-start;
  align-items: center;
}

.social-icon > img {
  height: 1.3rem;
  width: 1.3rem;
  object-fit: cover;
}

.copy-right {
  background: var(--seventh-color);
  color: white;
  text-align: center;
  padding: 0.8rem;
  font-size: 0.8rem;
}

@media (max-width: 998px) {
  .footer-container {
    position: relative;
    display: flex;
    justify-items: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    background: var(--main-color);
  }
  .footer-body {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 2rem;
    gap: 1rem;
  }
}
