.team-body{
    margin: 5rem 8rem;
    display: flex;
    flex-direction: column;
    text-align: start;
    justify-content: flex-start;
    gap: 1rem;
}

.team-content{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: flex-start;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding: 4rem 2rem;

}
.team-heading{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding-bottom: 20px;
}
.team-heading > h4{
    font-size: 24px;
    color: var(--second-font-color);
    font-weight: 600;
}
.team-heading > p{
    font-size: 0.9rem;
    text-align: center;
    color: var(--second-font-color);
    font-weight: 400;
    line-height: 28px;
}

.qac-table{
    width: 100%;
    overflow-x: auto;
}
.table-content > h4{
    font-size: 15px;
    color: var(--second-font-color);
    margin-bottom: 10px;
}

.organogram-body{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}



  
@media(max-width:998px){
    .team-body{
        margin: 3rem 2rem;
    }

    .organogram-body{
        overflow-x: auto;
    }
}